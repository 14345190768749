.form {
  &__title {
    text-align: center;
    font-size: 24px;
    color: #4f4f4f;
    line-height: 32px;
    margin-bottom: 24px;
  }
  label {
    color: #073812;
    font-size: 18px;
    line-height: 23px;
    &.ant-checkbox-wrapper{
      font-size: 16px;
    }
  }
  input {
    border: 1px solid #cbcbcb;
    padding: 20px 24px;
    border-radius: 6px;
    font-size: 20px;
  }
  &--full {
    width: 100%;
    input {
      width: 100%;
    }
  }
  .ant-checkbox {
    font-size: 16px;
    &-checked {
      .ant-checkbox-inner {
        background-color: #10ac84;
      }
    }
  }
}

@media screen and (max-width: 1600px){
  .form {
    &__title {
      font-size: 16px;
      line-height: 22px;
    }
    label {
      font-size: 12px;
      line-height: 16px;
      &.ant-checkbox-wrapper{
        font-size: 11px;
      }
    }
    .ant-checkbox {
      font-size: 11px;
    }
    input {
      padding: 12px 16px;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1140px){
  .form {
    &__title {
      font-size: 12px;
      line-height: 16px;
    }
    label {
      font-size: 10.5px;
      line-height: 12px;
      &.ant-checkbox-wrapper{
        font-size: 8px;
      }
    }
    .ant-checkbox {
      font-size: 8px;
    }
    input {
      padding: 10px 12px;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 640px){
  .form {
    &__title {
      font-size: 22px;
      line-height: 32px;
      text-align: left;
    }
    label {
      font-size: 16px;
      line-height: 24px;
      &.ant-checkbox-wrapper{
        font-size: 14px;
        line-height: 22px;
    }
    }
    .ant-checkbox {
      font-size: 14px;
      line-height: 22px;
    }
    input {
      padding: 15px 24px;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
