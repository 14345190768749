@import url("https://use.typekit.net/ojm1crp.css");
@import "~antd/dist/antd.css";
@import "./sass/_colors.scss";
// @import '~font-awesome/scss/font-awesome';

// @mixin fa-icon($type) {
//   @extend .fa;
//   @extend .fa-#{$type};
// }

// i {
//   @include fa-icon('star');
//   margin-right: 0.2rem;
// }

body {
  margin: 0;
  padding: 0;
  font-family: "sofia-pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/images/bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  font-size: 24px;
  line-height: 32px;
  height: auto;
}

#loan-portal {
  padding: 48px;
  margin: 0 auto;
  width: 90%;
}

.text {
  &--highlight {
    color: #5fbd71;
  }
}

.float{
  &--right{
    float: right;
  }
  &--left{
    float: left;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: transparent;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: #cbcbcb;
}

.ant-checkbox-checked::after {
  border: none;
}

@media screen and (min-width: 1601) {
  body {
    font-size: 24px;
    line-height: 32px;
  }
  #loan-portal {
    max-width: 1600px;
  }
}

@media screen and (max-width: 1600px) {
  body {
    font-size: 16px;
    line-height: 22px;
  }
  #loan-portal {
    max-width: 1440px;
    padding: 24px;
  }
}

@media screen and (max-width: 1140px) {
  body {
    font-size: 11px;
    line-height: 15px;
  }
  #loan-portal {
    max-width: 960px;
    padding: 18px;
  }
}

@media screen and (max-width: 640px) {
  body {
    font-size: 6px;
    line-height: 9px;
  }
  #loan-portal {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}
