#home-page{
  @import '/src/sass/headings';
  @import '/src/sass/buttons';
  @import '/src/sass/pages';
  font-size: 20px;
  color: #000000;
  h2{
    font-size: 40px;
  }
  .page{
    &__title{
      font-size: 26px;
      line-height: 34px;
      color: #000000;
    }
    &__cta{
      display: flex;
      justify-content: space-between;
      margin-bottom: 100px;
    }
  }
  .app__progress-stepper{
    margin-bottom: 64px;
  }
}


@media screen and (max-width: 1600px){
  #home-page{
    font-size: 14px;
    h2{
      font-size: 28px;
    }
    .page{
      &__title{
        font-size: 18px;
      }
    }
    .app__progress-stepper{
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 1140px){
  #home-page{
    font-size: 10px;
    h2{
      font-size: 20px;
    }
    .page{
      &__title{
        font-size: 13px;
      }
    }
    .app__progress-stepper{
      margin-bottom: 32px;
    }
  }
}

@media screen and (max-width: 640px){
  #home-page{
    font-size: 18px;
    line-height: 28px;
    h2{
      font-size: 28px;
      line-height: 36px;
    }
    .page{
      &__title{
        font-size: 26px;
        line-height: 40px;
    }
    }
    .app__progress-stepper{
      margin-bottom: 24px;
    }
  }
}