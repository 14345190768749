.page{
  &__container{
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 18px #00000014;
    padding: 40px;
  }
  &__wrapper{
    margin-bottom: 44px;
  }
}
.app__progress-stepper{
  margin-bottom: 64px;
}



@media screen and (max-width: 1600px){
  .app__progress-stepper{
    margin-bottom: 44px;
  }
  .page{
    &__container{
      padding: 32px;
    }
    &__wrapper{
      margin-bottom: 44px;
    }
  }
}

@media screen and (max-width: 1140px){
  .app__progress-stepper{
    margin-bottom: 32px;
  }
  .page{
    &__wrapper{
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: 640px){
  .app__progress-stepper{
    margin-bottom: 18px;
  }
  .page{
    &__wrapper{
      margin-bottom: 18px;
    }
  }
}