section.ant-layout,
header.ant-layout-header,
footer.ant-layout-footer {
  background: none;
  padding: 0;
}

header.ant-layout-header {
  height: auto;
  .logo {
    max-width: 256px;
  }
  .col--logo {
    margin-bottom: 120px;
  }
  h1 {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1600px) {
  header.ant-layout-header {
    .col--logo {
      margin-bottom: 64px;
      max-height: 56px;
    }
    .logo {
      max-width: 180px;
    }
  }
}

@media screen and (max-width: 1140px) {
  header.ant-layout-header {
    .col--logo {
      margin-bottom: 48px;
    }
    .logo {
      max-width: 128px;
    }
  }
}

@media screen and (max-width: 640px) {
  header.ant-layout-header {
    .col--logo {
      margin-bottom: 40px;
    }
    .logo {
      max-width: 110px;
    }
  }
}
