h1 {
  font-size: 40px;
  line-height: 52px;
}

h2 {
  font-size: 32px;
  line-height: 52px;
}

h3 {
  font-size: 26px;
  line-height: 34px;
}

h1,
h2,
h3 {
  margin-bottom: 24px;
  font-weight: 700;
  font-family: "sofia-pro", sans-serif;
  color: #073812;
}

@media screen and (max-width: 1600px) {
  h1 {
    font-size: 28px;
    line-height: 36px;
  }
  h2 {
    font-size: 22px;
    line-height: 36px;
  }
  h3 {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1140px) {
  h1 {
    font-size: 20px;
    line-height: 26px;
  }
  h2 {
    font-size: 16px;
    line-height: 26px;
  }
  h3 {
    font-size: 13px;
    line-height: 17px;
  }
}

@media screen and (max-width: 640px) {
  h1 {
    font-size: 12px;
    line-height: 15px;
  }
  h2 {
    font-size: 26px;
    line-height: 32px;
  }
  h3 {
    font-size: 8px;
    line-height: 9px;
  }
}
