.page{
  color: #000000;
  &__title{
    text-align: center;
    font-size: 24px;
    color: #4F4F4F;
    line-height: 32px;
    margin-bottom: 24px;
  }
  &__cta-helper{
    font-size: 22px;
    line-height: 34px;
    text-align: center;
  }
  &__intro{
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 32px;
  }
  p{
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 1600px){
  .page{
    &__title,
    &__intro{
      font-size: 16px;
      line-height: 22px;
    }
    &__cta-helper{
      font-size: 15px;
      line-height: 23px;
    }
  }
}

@media screen and (max-width: 1140px){
  .page{
    &__title,
    &__intro{
      font-size: 12px;
      line-height: 16px;
    }
    &__cta-helper{
      font-size: 11px;
      line-height: 17px;
    }
  }
}

@media screen and (max-width: 640px){
  .page{
    &__title{
      font-size: 20px;
      line-height: 38px;
      text-align: left;
    }
    &__cta-helper{
      font-size: 22px;
      line-height: 34px;
    }
    &__intro{
      display: none;
    }
  }
}