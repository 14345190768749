#gst-page {
  @import "/src/sass/forms";
  @import "/src/sass/buttons";

  .otp-input input {
    margin-right: 24px;
    width: 64px !important;
    height: 64px !important;
    text-align: center;
    outline: none;
    font-size: 32px;
    padding: 16px;
  }
  .row {
    &__otp-actions {
      padding-top: 200px;
    }
    &__gst-actions {
      button {
        width: 100%;
      }
    }
    .ant-form-item{
      margin-bottom: 12px;
    }
  }
  .error-display{
    color: #ff4d4f;
    text-align: right;
    font-size: 12px;
    margin-top: -24px;
  }
}

@media screen and (max-width: 1600px) {
  #gst-page {
    .otp-input input {
      width: 48px !important;
      height: 48px !important;
      font-size: 24px;
      padding: 12px;
    }
    .ant-form-item-label {
      padding-bottom: 4px;
    }
    .row__otp-actions {
      padding-top: 100px;
    }
  }
}

@media screen and (max-width: 1140px) {
  #gst-page {
    .otp-input input {
      width: 32px !important;
      height: 32px !important;
      margin-right: 12px;
      font-size: 16px;
      padding: 8px;
    }
    .ant-form-item-label {
      padding-bottom: 2px;
    }
  }
}

@media screen and (max-width: 640px) {
  #gst-page {
    .otp-input input {
      width: 48px !important;
      height: 48px !important;
      font-size: 28px;
      padding: 8px 10px 12px;
    }
    .row {
      &__otp-actions {
        padding-top: 64px;
      }
      &__gst-actions {
        .col__help{
          width: auto;
          margin: 32px auto 10px;
        }
        button {
          width: auto;
        }
      }
    }
  }
}
