#success-page {
  @import "/src/sass/headings";
  color: #8d9a97;
  font-size: 24px;
  line-height: 36px;
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

@media screen and (max-width: 1600px) {
  #success-page {
    font-size: 16px;
    line-height: 24px;
    h1 {
      font-size: 22px;
      line-height: 33px;
    }
  }
}

@media screen and (max-width: 1140px) {
  #success-page {
    font-size: 12px;
    line-height: 18px;
    h1 {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 640px) {
  #success-page {
    font-size: 18px;
    line-height: 22px;
    h1 {
      font-size: 26px;
      line-height: 32px;
    }
  }
}
