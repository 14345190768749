.button {
  cursor: pointer;
  border-radius: 4px;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  padding: 15px 32px;
  height: auto;
  box-shadow: none;
  &--primary {
    background-color: #5fbd71;
    color: #ffffff;
    border-color: #5fbd71;
  }
  &:disabled {
    background-color: #e1e1e1;
    cursor: not-allowed;
    border-color: #e1e1e1;
    &:hover {
      box-shadow: inherit;
    }
  }
  &--textual {
    background-color: transparent;
    color: #5fbd71;
    padding-left: 0;
    padding-right: 0;
    border: none;
    &.button--left {
      text-align: left;
      &.button--textual {
        padding-left: 0;
      }
    }
    &.button--right {
      text-align: right;
      &.button--textual {
        padding-right: 0;
      }
    }
    &:disabled {
      background-color: transparent;
      color: #e1e1e1;
      span {
        text-decoration: none;
      }
    }
    span {
      text-decoration: underline;
    }
  }
  &.button--full {
    width: 100%;
  }
}

@media screen and (max-width: 1600px) {
  .button {
    font-size: 14px;
    line-height: 18px;
    padding: 8px 16px;
  }
}

@media screen and (max-width: 1140px) {
  .button {
    font-size: 10px;
    line-height: 13px;
    padding: 6px 16px;
  }
}

@media screen and (max-width: 640px) {
  .button {
    font-size: 20px;
    line-height: 32px;
    padding: 11px 16px;
    &--textual {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
