.progress-stepper {
  height: 100px;
  border: 1px solid transparent;
  .step {
    width: 56px;
    overflow: visible;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    font-size: 24px;
    height: 100px;
    &__index {
      border: 1px solid #5FBD71;
      border-radius: 50%;
      color: #5FBD71;
      width: 56px;
      height: 56px;
      line-height: 28px;
      padding: 11px 0;
    }
    &__name {
      color: #000000;
      width: max-content;
      margin-top: 10px;
    }
    &--completed {
      .step__index {
        background-color: #5fbd71;
        color: #ffffff;
      }
    }
    &--start{
      .step__name{
        align-self: flex-start;
      }
    }
    &--end{
      .step__name{
        align-self: flex-end;
      }
    }
  }
  .connector--column{
    flex: 1;
  }
  .connection {
    &--wrapper {
      height: 100px;
      min-width: 100px;
      padding-top: 28px;
      .connector {
        background-color: #EBEBEB;
        height: 1px;
        width: 100%;
        height: 3px;
      }
    }
    &--completed {
      .connector {
        background-color: #5FBD71;
      }
    }
  }
}

@media screen and (max-width: 1600px){
  .progress-stepper{
    .step{
      font-size: 16px;
      width: 38px;
      &__index{
        height: 38px;
        width: 38px;
        line-height: 20px;
        padding: 6px 0;
      }
    }
    .connection {
      &--wrapper {
        padding-top: 19px;
      }
    }
  }
}

@media screen and (max-width: 1140px){
  .progress-stepper{
    .step{
      font-size: 11px;
      width: 28px;
      &__index{
        height: 28px;
        width: 28px;
        line-height: 13px;
        padding: 6px 0;
      }
    }
    .connection {
      &--wrapper {
        padding-top: 14px;
      }
    }
  }
}

@media screen and (max-width: 640px){
  .progress-stepper{
    .step{
      font-size: 14px;
      line-height: 22px;
      width: 40px;
      &__index{
        height: 40px;
        width: 40px;
        font-size: 20px;
        line-height: 32px;
        padding: 0;
      }
      &__name{
        display: none;
      }
      &--current{
        .step__name{
          display: block;
        }
      }
    }
    .connection {
      &--wrapper {
        min-width: auto;
        padding-top: 20px;
      }
    }
  }
}
